<!-- 会员招募-->
<template>
  <div class="recruit-agent">
    <!-- 招募志愿服务单位 -->
    <div class="banner pc-banner">
      <img :src="bannerUrl.title_img" alt="">
    </div>
    <div class="banner-mobile">
      <img :src="mobilebanner" class="gn-full">
    </div>
    <!-- 招募收费标准 -->
    <div class="charge">
      <div class="charge-main">
        <div class="title">招募收费标准</div>
        <div class="teble">
          <div style="display: flex;margin :0 auto ;">
            <div class="teble-main">
              <div class="teble-title">
                招募类型
              </div>
              <div class="teble-text">常务会长单位</div>
              <div class="teble-text">副会长单位</div>
              <div class="teble-text">理事单位</div>
              <div class="teble-text">普通会员</div>
            </div>
            <div class="teble-main">
              <div class="teble-title">
                会费
              </div>
              <div class="teble-text">10000元</div>
              <div class="teble-text">5000元</div>
              <div class="teble-text">2000元</div>
              <div class="teble-text">300元</div>
            </div>
            <div class="teble-main honor teble_block">
              <div class="teble-title">
                荣誉
              </div>
              <div class="teble-text">
                <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/2b70144e52814e939ab1eaaf289923e3.png" alt="">
                常务会长铜牌匾
              </div>
              <div class="teble-text">
                <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/9525b650dff147cb8ba3decbdfc63111.png" alt="">
                副会长铜牌匾
              </div>
              <div class="teble-text">
                <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/6e1cc3720a694bbbb8585a70bf615f45.png" alt="">
                理事铜牌匾
              </div>
              <div class="teble-text">
                <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/b4734d0547a040ce926b0ef243f17f41.png" alt="">
                会员铜牌匾
              </div>
            </div>
            <div class="teble-main teble_block" >
              <div class="teble-title">
                福利
              </div>
              <div class="teble-block">
                <div class="block-text block-text1">搭政企间桥梁新型农技推广行业自律建设</div>
                <div class="block-line"></div>
                <div class="block-text block-text2">赠送宝乡通®农资门店数字智能助手1台</div>
                <div class="block-text block-text3">价值2260元(京东价)</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 加入方式 -->
        <div class="title join">加入方式</div>
        <!-- 扫码入会 -->
        <div class="join-main">
          <div class="main-block">
            <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/6c2cb09f8c0446c1892919e1abedfc19.png" alt="">
            <div>扫码入会</div>
          </div>
          <div class="block-icon">
            <img v-if="windowWidth>768" src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/0aa7fadeb5cf41729c9e65f6d498e64d.png" alt="">
            <img v-else src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/6a3429181d894a99bd8ded766ed65812.png" alt="">

          </div>
          <div class="main-block">
            <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/98605f3b1f5b424e9224402f936cd5f9.png" alt="">
            <div>缴费</div>
          </div>
          <div class="block-icon">
            <img v-if="windowWidth>768" src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/0aa7fadeb5cf41729c9e65f6d498e64d.png" alt="">
            <img v-else src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/6a3429181d894a99bd8ded766ed65812.png" alt="">

          </div>
          <div class="main-block">
            <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/c4cbc193b243408a98c88ca252acc778.png" alt="">
            <div>上门授牌</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 扫码图片入会 -->
    <div class="scancode" >
      <div class="scancode-main" >
        <div class=" block-img">
          <img width="137" height="137" src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/50d4c2e756234243a124fdc4c6d1a3b7.png" alt="">
          <div class="title">扫码入会</div>
        </div>
        <div class="icon-right1 icon-right">
          <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/eb9cfc5d39594cd79fbbd5c33d3bd97f.png" alt="">
        </div>
        <div class="main-block mediblock">
          <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/2a1f77aaffe7436182fdde674f01e92a.png" alt="">
          <div class="block-text">选择会费类型</div>
        </div>
        <div class="icon-right2 icon-right" v-if="windowWidth>=768">
          <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/eb9cfc5d39594cd79fbbd5c33d3bd97f.png" alt="">
        </div>
        <div class="main-block mediblock">
          <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/cd30c2a4c99244c89fb8c6d1709443d4.png" alt="">
          <div class="block-text">填写相关信息</div>
        </div>
        <div class="icon-right3 icon-right">
          <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/eb9cfc5d39594cd79fbbd5c33d3bd97f.png" alt="">
        </div>
        <div class="main-block mediblock">
          <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/42524b0ea1d04e8da9cfe5a317aeb2e0.png" alt="">
          <div class="block-text">
            <p>填写完毕后点击绿色按钮，</p>
            <p>支付会费即入会成功。</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 咨询 -->
    <div class="consult">
      <div class="consult-main">
        <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/e579efdf00ad423aa5088cee5ac96c19.png" alt="">
        咨询：文经理 13422276741(微信同号)
      </div>
    </div>
  </div>
</template>

<script>
import { makeAnAppointment } from '@/api/officialWebsite'
import { getBannerList } from '@/api/admin'

export default {
  name: 'recruit-agent',
  data () {
    return {
      bannerUrl: {
        title_img: 'https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/851067a943e24b2e8e4b0e1852392384.png'
      },
      mobilebanner: 'https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/997209e973ce4184b5d2d1f29df51965.png',
      bannerTitle: '',
      selfSupport: {},
      thirdpartnar: {},
      operatingRules: {},
      cooperation1: {},
      cooperation2: {},
      cooperation3: {},
      params: {
        name: '',
        phone: '',
        address: '',
        company: '',
        remark: ''
      },
      timer: null,
      oneKeyGeneration: {},
      traditionalMode: {},
      forwardWarehouse: {},
      windowWidth: 1024

    }
  },
  created () {
    this.getbannerList()
  },
  mounted () {
    this.windowWidth = window.document.body.clientWidth
    window.addEventListener('resize', this.resizePage)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizePage)
  },
  methods: {
    // 页面大小改变
    resizePage () {
      this.windowWidth = window.document.body.clientWidth
    },
    hendleSeve () {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        if (this.params.company.trim() === '') {
          this.$message.error('请填写公司名称')
          return
        }
        if (this.params.name.trim() === '') {
          this.$message.error('请填写姓名')
          return
        }
        if (this.params.phone.trim() === '') {
          this.$message.error('请填写电话号码')
          return
        }
        if (!/^((0\d{2,3}(-)?\d{7,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/.test(this.params.phone)) {
          this.$message.error('请填写正确的电话号码')
          return
        }

        makeAnAppointment(this.params)
          .then((res) => {
            this.$message.success('预约成功')
            this.params.company = ''
            this.params.name = ''
            this.params.phone = ''
          })
          .catch((error) => {
            console.error(error)
            this.$message.success(error)
          })
      }, 500)
    },
    getbannerList () {
      const obj = {
        page: 1,
        per_page: 200
      }
      getBannerList(obj).then(res => {
        console.log('banner', res)
        for (let i = 0; i < res.length; i++) {
          if (res[i].title === '会员招募') {
            this.bannerUrl = res[i].list[0]
            break
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.banner-mobile{
  display: none;
}

.recruit-agent{
  // 招募志愿服务单位
  .banner{
    margin: 0 auto;
    height: 526px;
    background-size: cover;
    img{
      width: 100%;
      height: 100%;
    }
  }
  // 招募收费标准
  .charge{
    .charge-main{
      width: 1200px;
      margin: 0 auto;
      margin-top: 51px;
      .title{
        font-size: 32px;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        color: #000000;
        line-height: 38px;
        text-align: center;
        margin-bottom: 34px;
      }
      //表格
      .teble{
        width: 905px;
        margin: 0 auto;
        display: flex;
        .teble-main{
          width: 182px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 2px;
          .teble-title{
            width: 100%;
            height: 48px;
            background: #0c855a;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-400;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            line-height: 48px;
            margin-bottom: 2px;
          }
          .teble-text{
            width: 100%;
            height: 72px;
            background: #f5f5f5;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-400;
            font-weight: 400;
            text-align: center;
            color: #000000;
            line-height: 72px;
            margin-bottom: 3px;
          }
          .teble-block{
            width: 100%;
            height: 296px;
            background: #f5f5f5;
            .block-text{
              font-size: 16px;
              font-family: PingFang SC, PingFang SC-400;
              font-weight: 400;
              text-align: center;
              color: #000000;
              line-height: 19px;
              width: 96px;
              margin: 0 auto;
            }
            .block-text1{
              margin-top: 36px;
              margin-bottom: 23px;
            }
            .block-line{
                width: 131px;
                height: 0px;
                border: 0.5px dashed #bebebe;
                margin: 0 auto;
            }
            .block-text2{
              margin-top: 24px;
              margin-bottom: 9px;
            }
          }
        }
        .honor{
          width: 352px;
          .teble-text{
            text-align: left;
            padding-left: 81px;
            img{
              padding-right: 22px;
            }
          }
        }
      }
      //加入方式
      .join{
        margin-top: 68px;
        margin-bottom: 53px;
      }
      .join-main{
        width: 1200px;
        // height: 266px;
        border-bottom: dashed 1px #addecd;
        display: flex;
        justify-content: center;
        padding-bottom: 47px;
        .block-icon{
          width: 109px;
          height: 109px;
          display: flex;
          align-items: center;
          margin-left: 50px;
          margin-right: 56px;

        }
        .main-block{
          display: flex;
          flex-direction: column;
          img{
            width: 109px;
            height: 109px;
          }
          div{
            margin-top: 29px;
            font-size: 20px;
            font-family: PingFang SC, PingFang SC-400;
            font-weight: 400;
            text-align: center;
            color: #333333;
            line-height: 23px;
          }
        }
      }
    }
  }
  .scancode{
    width: 1200px;
    margin: 0 auto;
    margin-top: 84px;
    .scancode-main{
      display: flex;
      width: 1002px;
      margin: 0 auto;
      .main-block{
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
          width: 200px;
          height: 434px;
        }
        .block-text{
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-400;
          font-weight: 400;
          color: #333333;
          line-height: 19px;
          text-align: center;
          margin-top: 46px;
        }
      }
      .block-img{
        width: 137px;
        height: 434px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title{
          font-size: 24px;
          font-family: PingFang SC, PingFang SC-400;
          font-weight: 400;
          text-align: CENTER;
          color: #000000;
          line-height: 28px;
          margin: 0 auto;
          margin-top: 18px;
        }
      }
      .icon-right{
        height: 400px;
        display: flex;
        align-items: center;
        img{
          width: 28px;
          height: 32px;
        }
      }
      .icon-right1{
        margin: 0 34px 0 47px;
      }
      .icon-right2{
        margin: 0 28px 0 23px;
      }
      .icon-right3{
        margin: 0 19px 0 29px;
      }
    }
  }
  .consult{
    width: 1200px;
    margin: 0 auto;
    margin-top: 98px;
    margin-bottom: 72px;
    .consult-main{
      width: 535px;
      height: 42px;
      background: #d8ffe5;
      text-align: center;
      margin: 0 auto;
      font-size: 24px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      color: #0c855a;
      line-height: 42px;
      img{
        width: 31px;
        height: 33px;
      }
    }
  }
}
</style>

<style scoped lang='scss'>
// 手机版 样式
@import './media1';
</style>
